import http from '@/util/axios'

// 用户管理
export const userLogin = data => http.post('adminUser/login', data)
export const userResetPassword = data => http.factoryPost('adminUser/reset_password', data)
export const userGetPage = data => http.factoryGet('adminUser/get_page', data)
export const userGetList = data => http.factoryGet('adminUser/get_list', data)
export const userSave = data => http.factoryPost('adminUser/save', data)
export const userDelete = data => http.factoryPost('adminUser/delete', data)
export const factoryUserGetPage = data => http.factoryGet('adminUser/get_factory_page', data)
export const doctorUserGetPage = data => http.factoryGet('adminUser/get_doctor_page', data)
export const factoryUserResetPwd = data => http.factoryPost('adminUser/update_factory_password', data)
export const doctorUserResetPwd = data => http.factoryPost('adminUser/update_doctor_password', data)
export const factoryUserUnlock = data => http.factoryPost('adminUser/unlock_factory', data)
export const factoryUserUnlockAll = data => http.factoryPost('adminUser/unlock_all_factory', data)
export const factoryUserValid = data => http.factoryPost('adminUser/valid_factory', data)
export const doctorUserUnlock = data => http.factoryPost('adminUser/unlock_doctor', data)
export const doctorUserUnlockAll = data => http.factoryPost('adminUser/unlock_all_doctor', data)
export const doctorUserValid = data => http.factoryPost('adminUser/valid_doctor', data)
export const RSAGenerate = data => http.factoryGet('adminUser/new_rsa', data)
// 角色管理
export const roleSave = data => http.factoryPost('adminRole/save', data)
export const roleDelete = data => http.factoryPost('adminRole/delete', data)
export const roleGetList = data => http.factoryGet('adminRole/get_list', data)
export const roleSaveAuth = data => http.factoryPost('adminRole/save_auth', data)
export const roleGetAuth = data => http.factoryGet('adminRole/get_auth', data)
// 模块管理
export const moduleSave = data => http.factoryPost('adminModule/save', data)
export const moduleDelete = data => http.factoryPost('adminModule/delete', data)
export const moduleGetList = data => http.factoryGet('adminModule/get_list', data)
export const moduleSort = data => http.factoryPost('adminModule/sort', data)
//技工模块管理
export const workerAppModuleSave = data => http.factoryPost('workerAppModule/save', data)
export const workerAppModuleDelete = data => http.factoryPost('workerAppModule/delete', data)
export const workerAppModuleGetList = data => http.factoryGet('workerAppModule/get_list', data)
export const workerAppModuleSort = data => http.factoryPost('workerAppModule/sort', data)
// 部门管理
export const deptSave = data => http.factoryPost('adminDept/save', data)
export const deptDelete = data => http.factoryPost('adminDept/delete', data)
export const deptGetList = data => http.factoryGet('adminDept/get_list', data)
export const deptSort = data => http.factoryPost('adminDept/sort', data)
// 操作日志
export const adminUserOperLogGetPage = data => http.factoryGet('adminUserOperLog/get_page', data)
// 功能版本
export const systemProductSave = data => http.factoryPost('systemProduct/save', data)
export const systemProductGet = data => http.factoryGet('systemProduct/get', data)
// 工厂模块
export const sysModuleGetList = data => http.factoryGet('sysModule/get_list', data)
// 版本更新说明
export const versionUpdateContentSave = data => http.factoryPost('versionUpdateContent/save', data)
export const versionUpdateContentDelete = data => http.factoryPost('versionUpdateContent/delete', data)
export const versionUpdateContentGetPage = data => http.factoryGet('versionUpdateContent/get_page', data)
export const versionUpdateContentGetVersion = data => http.factoryGet('versionUpdateContent/get_version', data)