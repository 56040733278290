import {createRouter, createWebHistory} from 'vue-router'
import homeView from '../views/homeView.vue'
import error from '../views/404.vue'
import loginView from '../views/loginView'
import {ElMessage} from "element-plus";

const routes = [
    {
        path: '/',
        name: 'login',
        component: loginView,
    },
    {
        path: '/admin',
        name: 'home',
        component: homeView,
        children: [
            {
                path: 'homeInfo',
                name: 'homeInfo',
                component: () => import('../views/system/homeInfo'),
            },
            {
                path: 'factoryList',
                name: 'factoryList',
                component: () => import('../views/factory/factoryList'),
            },
            {
                path: 'groupList',
                name: 'groupList',
                component: () => import('../views/factory/groupList'),
            },
            {
                path: 'factoryProductList',
                name: 'factoryProductList',
                component: () => import('../views/factory/factoryProductList'),
            },
            {
                path: 'factoryPageRecordList',
                name: 'factoryPageRecordList',
                component: () => import('../views/factory/factoryPageRecordList'),
            },
            {
                path: 'factoryLoginRecordList',
                name: 'factoryLoginRecordList',
                component: () => import('../views/factory/factoryLoginRecordList'),
            },
            {
                path: 'factoryUserList',
                name: 'factoryUserList',
                component: () => import('../views/factory/factoryUserList'),
            },
            {
                path: 'doctorUserList',
                name: 'doctorUserList',
                component: () => import('../views/factory/doctorUserList'),
            },
            {
                path: 'roleList',
                name: 'roleList',
                component: () => import('../views/system/roleList'),
            },
            {
                path: 'moduleList',
                name: 'moduleList',
                component: () => import('../views/system/moduleList'),
            },
            {
                path: 'workerAppModule',
                name: 'workerAppModule',
                component: () => import('../views/system/workerAppModule'),
            },
            {
                path: 'userList',
                name: 'userList',
                component: () => import('../views/system/userList'),
            },
            {
                path: 'deptList',
                name: 'deptList',
                component: () => import('../views/system/deptList'),
            },
            {
                path: 'projectList',
                name: 'projectList',
                component: () => import('../views/project/projectList'),
            },
            {
                path: 'taskList',
                name: 'taskList',
                component: () => import('../views/project/taskList'),
            },
            {
                path: 'factoryHelp',
                name: 'factoryHelp',
                component: () => import('../views/project/factoryHelp'),
            },
            {
                path: 'helpHistory',
                name: 'helpHistory',
                component: () => import('../views/project/helpHistory'),
            },
            {
                path: 'adminUserOperLog',
                name: 'adminUserOperLog',
                component: () => import('../views/system/adminUserOperLog'),
            },
            {
                path: 'systemProduct',
                name: 'systemProduct',
                component: () => import('../views/factory/systemProduct'),
            },
        ]
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'error',
        component: error
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

// 路由跳转前的拦截器
router.beforeEach((to, from) => {
    // console.log(to)
    // console.log(from)
    // 一般用来判断 没有token的时候 访问任何页面都会跳转回登录页面
    // 1，判断当前跳转页面（to.name）是否是登录页面，如果不是判断有无token
    // 2，判断sessionStorage或者localStorage里面是不是有token
    // 没有token跳转到login
    // 有token直接调用next方法
    // 3，判断是否有页面访问的权限（首页都可以访问)
    if (to.name !== 'login') {
        let token = sessionStorage.getItem('token')
        if (!token || token === 'undefined') {
            return {name: 'login'}
        } else {
            if (to.name !== 'homeInfo') {
                let auth = sessionStorage.getItem('auth')
                if (auth.indexOf('\"' + to.name + '\"') < 0) {
                    ElMessage.error('没有页面访问权限，请检查')
                    return false
                }
            }
        }
    }
})
export default router